.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-Main {
   display:flex;
   flex-wrap: nowrap;
   flex: 1;
   white-space: pre-line;
   text-overflow: clip;
   width: 156;
   height: 32;
   overflow-y: auto;
   text-align: left;

}

.App-OriginImg {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline;
  overflow-y: auto;
  float: left;
  margin-left: 10px;

}
.checkbox{
  vertical-align: middle;
}
.alert {
  overflow-y: auto;
}
.div-inline {

    width:400px;
    float:left;
    display:inline;
    margin-left: 40px;
    margin-top: 30px;

}

.App-link {
  color: #61dafb;
}

body {
  line-height: 0.8;
}
body.light {
  font: 12px
}

.toggleButton {
  padding: 10px 20px;
  margin: 5px;
  background-color: grey;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* 按钮激活状态 */
.active {
  background-color: green;
}

.custom-button {
  background-color: #1974eb; /* 绿色背景 */
  border: none; /* 无边框 */
  color: white; /* 白色文字 */
  padding: 3px 6px; /* 内边距 */
  text-align: center; /* 居中对齐 */
  text-decoration: none; /* 去除下划线 */
  display: inline-block; /* 让按钮并排显示 */
  font-size: 12px; /* 字体大小 */
  margin: 3px 6px; /* 按钮间距 */
  cursor: pointer; /* 鼠标样式为指针 */
  border-radius: 5px; /* 圆角 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  transition: background-color 0.3s ease; /* 背景颜色渐变效果 */
}

 .custom-button:hover {
  background-color: #45a049; /* 悬停时变色 */
}

.progress-container {
  width: 100%;
  background-color: #ddd;
  height: 10px;
  cursor: pointer;
  position: relative;
  margin-top: 5px;

}

.progress-container .progress-bar {
  height: 100%;
  background-color: #4CAF50;
  width: 0%;

}
.markers-container {
  position: absolute;
  top: -5px;
  width: 100%;
  height: 10px;
  
  pointer-events: none; /* Ensure markers don't block progress bar clicks */
}

.marker {
  position: absolute;
  width: 10px;              /* 增加标记的宽度 */
  height: 20px;             /* 增加标记的高度 */
  background-color: red;   /* 标记的背景色 */
  border-radius: 50%;      /* 圆形标记 */
  border: 2px solid #fff;  /* 白色边框 */
  cursor: pointer;          /* 鼠标悬停时显示为手型 */
  transform: translateX(-50%); /* 使标记在进度条中心对齐 */
  pointer-events: all;      /* 确保标记能够响应点击事件 */
}

.marker:hover {
  background-color: darkred; /* 悬停时标记颜色变深 */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* 悬停时添加阴影 */
}
.video-container {
  position: relative;
  width: 1080px;
  height: 700px;
  margin: 0 auto; /* 居中布局 */
}

 video,#overlayCanvas {
  position: absolute;
  width: 1080px;
  height: 700px;
  margin-top: 30px;
}
video {
  display: block;
  z-index: 0; 
  margin-bottom: 10px; /* Add space between the video/canvas and progress bar */
}

.sheet-header { 
  font-size: 20px; /* 增大字体 */
  font-weight: bold; /* 加粗字体 */
  margin-top: 10px;
}
.sheet-content {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  overflow: auto;
}
table {
  width: 100%;
  min-width: 1000px; /* 视需要设置最小宽度，确保大列数有足够空间 */
  border-collapse: collapse;
  background-color: #fff;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  white-space: nowrap; /* 保证内容不换行 */
}

#overlayCanvas {
  z-index: 1; /* 确保 canvas 覆盖在 video 上 */
  pointer-events: none; /* 不阻止对视频的交互 */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
