body {
    line-height: 1.2;

}

.light{
    font-size: 12px;
    z-index: 99;
}

.light .input-group{
    margin-bottom: 0px;

}

.my-3 {
    margin-bottom: 0rem;

}
.custom-file-label {
    z-index: 0;
}

.input-group {
    z-index: 0;
}